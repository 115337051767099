<template>
    <div class="pa-5 ma-3 white rounded-lg">
        <v-row>
            <v-col cols="8">
                <div class="subheading grey--text mb-9">Promo Code List</div>
            </v-col>
            <v-col cols="4" class="text-right">
                <v-btn depressed color="grey darken-4" class="white--text" to="/promo/new">
                    <v-icon left>mdi-plus</v-icon>
                    New
                </v-btn>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="6" offset="6" class="pt-0">
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Filter" single-line hide-details></v-text-field>
            </v-col>
        </v-row>
        
        <v-data-table :headers="headers" :items="items" :footer-props="paginationOptions" :search="search"
            item-key="id" :options="pagination" :loading="loading" @click:row="toPromoCodeDetails">
            <template v-slot:item.minAmount="{ item }">
                {{ formatMoney( item.minAmount ) }}
            </template>
            <template v-slot:item.amount="{ item }">
                {{ formatMoney( item.amount ) }}
            </template>
            <template v-slot:item.active="{ item }">
                <v-chip x-small label :color="item.active ? 'success' : 'error'">{{ item.active ? 'Yes' : 'No' }}</v-chip>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { _st } from '@/softech';

export default {
    data: () => ({
        headers: [
            { text: 'Code', value: 'code', sortable: true },
            { text: 'Description', value: 'description', sortable: true },
            { text: 'Min. amount', value: 'minAmount', sortable: true, align: 'right' },
            { text: 'Amount', value: 'amount', sortable: true, align: 'right' },
            { text: 'Active', value: 'active', sortable: true }
        ],
        pagination: {
            sortBy: ['code']
        },
        paginationOptions: { itemsPerPageOptions: [ 25, 50, -1 ] },
        loading: false,
        search: '',
    }),
    computed: {
        ...mapGetters({
            items: 'promo/promoCodes'
        }),
    },
    mounted() {
        this.init();
    },
    methods: {
        ...mapActions({
            loadPromoCodes: 'promo/loadPromoCodes'
        }),
        async init() {
            try {
                this.loading = true;
                let res = await this.loadPromoCodes();
                this.loading = false;

                if( !_st.isNUE( res ) && !res.status ) {
                    this.mxShowMessage( res.message, res.error ? 'error' : 'warning' );
                    return false;
                }
            }
            catch(error) {
                this.loading = false;
                this.mxShowMessage( 'ERROR: ' + error, 'error' );
            }
        },
        toPromoCodeDetails( code ) {
            this.$router.push(`/promo/${code.code}`);
        },
        formatMoney( val ) {
            return _st.toMoney( val );
        }
    }
}
</script>

<style lang="scss" scoped>

</style>